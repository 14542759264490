import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Input,
  Layout,
  notification,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import DndTable from '../../components/dndTable';
import { HEALTH_PROFILE_ACCOUNT_TYPES } from '../../../util/healthProfileConstants';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import {
  deleteHealthProfile,
  fetchHealthProfileExcel,
  fetchHealthProfiles,
  patchHealthProfile,
  patchHealthProfileBulk,
  postHealthProfileCopy,
} from '../../../services/healthProfileService';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';

const HealthProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { diseaseTypeInfo, pagination } = useSelector((state) => {
    return {
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data ?? [],
      pagination: state.paginationReducer.healthProfilePagination,
    };
  });

  const diseaseTypeList = useMemo(() => {
    const arr = [
      {
        label: '전체 질환',
        value: 'all',
      },
      ...diseaseTypeInfo?.map((d) => ({
        label: d.krName,
        value: d.id,
      })),
    ];
    return arr;
  }, [diseaseTypeInfo]);

  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const confirmRef = useRef(() => {});

  const disableDnd =
    Object.values(pagination.filter).filter((v) => v !== null).length > 0;

  const getDiseaseTypeList = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  const getData = useCallback(async (cat) => {
    setIsLoading(true);
    const res = await fetchHealthProfiles(cat);
    setDataSource(res);
    dispatch(paginationCreators.setValue((pagination.total = res.length)));
    setIsLoading(false);
  }, []);

  // fetching data on mount
  useEffect(() => {
    getData(pagination.category);
    setSelectedRowKey(null);
    setOrderHistory([]);
  }, [pagination.category, getData]);

  useEffect(() => {
    getDiseaseTypeList();
  }, [getDiseaseTypeList]);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const handleCreateNewHealthProfileWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'healthProfile' });
  };

  const handleCreateEditHealthProfileWindow = (id) => {
    if (findWindowById(id)) {
      alert('이미 편집중인 설문입니다.');
      return;
    }
    const hasAnsweredUser =
      dataSource.find((d) => d.id === id)?.answerCount > 0;
    createWindow({ id, dataType: 'healthProfile', hasAnsweredUser });
  };

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData(pagination.category);
      setOrderHistory([]);
    },
    [getData, pagination.category, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  // 데이터 갱신(복사, 삭제, 추가 등), 필터 적용시 순서 원복
  useEffect(() => {
    if (disableDnd && orderHistory.length > 0) {
      notification.warning({
        message: '설문 순서 변경이 저장되지 않고 초기화되었습니다.',
        key: 'disableDndWarning',
      });
      getData(pagination.category);
      setOrderHistory([]);
    }
  }, [
    disableDnd,
    dataSource,
    orderHistory.length,
    getData,
    pagination.category,
  ]);

  const patchIsApp = async (id, isApp) => {
    try {
      await patchHealthProfile(id, { isApp });
      getData(pagination.category);
      notification.success({
        message: '앱 노출 상태가 변경되었습니다.',
        key: 'patchIsAppSuccess',
      });
    } catch (e) {
      notification.error({
        message: '앱 노출 변경에 실패했습니다.',
        key: 'patchIsAppFailed',
      });
      throw e;
    }
  };

  const confirmOrder = async () => {
    try {
      await patchHealthProfileBulk(orderHistory);
      getData(pagination.category);
      notification.success({
        message: '설문 순서가 변경되었습니다.',
        key: 'confirmOrderSuccess',
      });
      setOrderHistory([]);
    } catch (e) {
      notification.error({
        message: '설문 순서 변경에 실패했습니다.',
        key: 'confirmOrderFailed',
      });
      throw e;
    }
  };

  const onSearch = (value) => {
    confirmRef.current(value);
  };

  const routeToAnswerPage = (healthProfileId) => {
    const record = dataSource.find((d) => d.id === healthProfileId);
    history.push('/healthProfileUsers', {
      healthProfileId,
      title: record?.title,
    });
  };

  const routeToQuestionAnswerPage = (healthProfileId) => {
    const record = dataSource.find((d) => d.id === healthProfileId);
    history.push('/healthProfileQuestion', {
      healthProfileId,
      hasAnsweredUser: record?.answerCount > 0,
      title: record?.title,
    });
  };

  const onCopy = async () => {
    try {
      if (confirm('선택한 설문을 복사하시겠습니까?')) {
        await postHealthProfileCopy(selectedRowKey);
        getData(pagination.category);
        notification.success({
          message: '선택한 설문이 복사되었습니다.',
          key: 'copySuccess',
        });
      }
    } catch (e) {
      notification.error({
        message: '선택한 설문 복사에 실패했습니다.',
        key: 'copyFailed',
      });
      throw e;
    }
  };

  const onDelete = async () => {
    try {
      if (confirm('선택한 설문을 삭제하시겠습니까?')) {
        await deleteHealthProfile(selectedRowKey);
        getData(pagination.category);
        notification.success({
          message: '선택한 설문이 삭제되었습니다.',
          key: 'deleteSuccess',
        });
      }
    } catch (e) {
      notification.error({
        message: '선택한 설문 삭제에 실패했습니다.',
        key: 'deleteFailed',
      });
      throw e;
    }
  };

  const onDownloadExcel = async () => {
    try {
      const title = dataSource.find((d) => d.id === selectedRowKey)?.title;
      await fetchHealthProfileExcel(selectedRowKey, title);
      notification.success({
        message: '선택한 설문이 다운로드 되었습니다.',
        key: 'downloadSuccess',
      });
    } catch (e) {
      notification.error({
        message: '선택한 설문 다운로드에 실패했습니다.',
        key: 'downloadFailed',
      });
      throw e;
    }
  };

  const columnSchema = [
    {
      title: '순서 변경',
      dataIndex: 'sort',
      key: 'sort',
      width: 90,
      align: 'center',
    },
    {
      title: '선택',
      dataIndex: 'rowSelection',
      key: 'rowSelection',
      type: 'radio',
      width: 60,
      align: 'center',
    },
    {
      title: '설문 구분',
      dataIndex: 'subCategory',
      key: 'subCategory',
      render: (subCategory) => {
        return <span>{subCategory?.name}</span>;
      },
    },
    {
      title: '설문 제목',
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => (
        <Button
          type="link"
          style={{
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'normal',
          }}
          onClick={() => {
            handleCreateEditHealthProfileWindow(record.id);
          }}
        >
          {title}
        </Button>
      ),
      filteredValue: pagination.filter.title || null,
      onFilter: (value, record) => {
        return record.title.includes(value);
      },
      filterDropdown: ({ confirm, setSelectedKeys }) => {
        confirmRef.current = (keyword) => {
          if (keyword.length > 0) {
            setSelectedKeys([keyword]);
            dispatch(
              paginationCreators.setValue((pagination.filter.title = keyword)),
            );
          } else {
            setSelectedKeys(null);
            dispatch(
              paginationCreators.setValue((pagination.filter.title = null)),
            );
          }

          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
    },
    {
      title: '설문 설명',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('YY-MM-DD'),
    },
    {
      title: '앱 노출',
      dataIndex: 'isApp',
      key: 'isApp',
      width: 80,
      render: (isApp, record) => (
        <Switch
          checked={isApp}
          onChange={(value) => {
            patchIsApp(record.id, value);
          }}
        />
      ),
      filters: [
        { text: 'ON', value: true },
        { text: 'OFF', value: false },
      ],
      filteredValue: pagination.filter.isApp || null,
      onFilter: (value, record) => record.isApp === value,
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      render: (diseaseTypes) => (
        <>
          {diseaseTypes?.map((d) => (
            <TagWrapper text={d.name} key={d.id} />
          ))}
          {diseaseTypes.length === 0 && <TagWrapper text="전체 질환" />}
        </>
      ),
      filters: diseaseTypeList,
      filteredValue: pagination.filter.diseaseTypes || null,
      onFilter: (value, record) => {
        const isDiseaseMatched = record.diseaseTypes
          .map((d) => d.id)
          .includes(value);
        const isAllDiseaseMatched =
          value === 'all' && record.diseaseTypes.length === 0;
        return isDiseaseMatched || isAllDiseaseMatched;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Select
            style={{
              marginRight: 8,
              width: 250,
            }}
            placement="bottomLeft"
            defaultValue={pagination.filter.diseaseTypes ?? null}
            value={selectedKeys}
            showSearch
            mode="multiple"
            placeholder="질환을 선택하세요"
            options={diseaseTypeList}
            filterOption={(input, { label }) =>
              label.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => setSelectedKeys(e)}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters()}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: '설문 대상 계정 유형',
      dataIndex: 'accountTypes',
      key: 'accountTypes',
      render: (targetAccountTypes) =>
        targetAccountTypes?.map((t) => (
          <TagWrapper key={t} text={HEALTH_PROFILE_ACCOUNT_TYPES[t]} />
        )),
      filters: Object.entries(HEALTH_PROFILE_ACCOUNT_TYPES).map(
        ([key, value]) => ({
          text: value,
          value: key,
        }),
      ),
      filteredValue: pagination.filter.accountTypes || null,
      onFilter: (value, record) => record.accountTypes.includes(value),
    },
    {
      title: '응답자 수',
      dataIndex: 'answerCount',
      key: 'answerCount',
    },
    {
      title: '응답 유저 페이지 이동',
      dataIndex: 'answerPage',
      key: 'answerPage',
      render: (_, record) => (
        <Button
          onClick={() => {
            routeToAnswerPage(record.id);
          }}
        >
          응답 유저 목록 &gt;
        </Button>
      ),
    },
    {
      title: '문항 / 답변 관리 페이지 이동',
      dataIndex: 'questionAnswerPage',
      key: 'questionAnswerPage',
      render: (_, record) => (
        <Button
          onClick={() => {
            routeToQuestionAnswerPage(record.id);
          }}
        >
          문항 / 답변 관리 &gt;
        </Button>
      ),
    },
  ];

  const columns =
    pagination.category === 'NORMAL'
      ? columnSchema.filter((column) => column.key !== 'subCategory')
      : columnSchema;

  const TagWrapper = ({ text }) => (
    <div className="tagWrapper" style={{ color: 'rgb(51, 51, 51)' }}>
      {text}
    </div>
  );

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="healthProfile" />
        <Layout className="right-layout">
          <TitleBreadcrumb title="정보" subTitle="설문" className="white-bg" />
          <Layout.Content className="site-layout-background contentStyle">
            <Tabs
              defaultActiveKey={pagination.category}
              onChange={(k) => {
                dispatch(
                  paginationCreators.setValue((pagination.category = k)),
                );
              }}
            >
              <Tabs.TabPane tab="레어노트 설문" key="NORMAL" />
              <Tabs.TabPane tab="연구 설문" key="RESEARCH" />
              <Tabs.TabPane tab="환우회 설문" key="PATIENT_GROUP" />
            </Tabs>
            <Row justify="end">
              <span className="searchResult">
                검색결과 {pagination.total}개
              </span>
              <Input.Search
                placeholder="검색어를 입력해주세요."
                allowClear
                className="searchStyle"
                onSearch={onSearch}
                defaultValue={pagination.filter.title}
              />
            </Row>
            <Row justify="space-between" style={{ marginBottom: 8 }}>
              <Row style={{ gap: 6 }}>
                <Button
                  type="primary"
                  onClick={handleCreateNewHealthProfileWindow}
                >
                  추가
                </Button>
                <Button
                  type="primary"
                  disabled={!selectedRowKey}
                  onClick={onCopy}
                >
                  복사
                </Button>
                <Button
                  type="primary"
                  disabled={!selectedRowKey}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              </Row>
              <Row style={{ gap: 6 }}>
                <Button
                  type="primary"
                  disabled={orderHistory.length === 0}
                  onClick={confirmOrder}
                >
                  설문 순서 확정
                </Button>
                <Button
                  type="primary"
                  disabled={
                    !selectedRowKey ||
                    dataSource.find((d) => d.id === selectedRowKey)
                      ?.answerCount === 0
                  }
                  onClick={onDownloadExcel}
                >
                  설문 다운로드
                </Button>
              </Row>
            </Row>
            <DndTable
              dataSource={dataSource}
              setDataSource={setDataSource}
              columns={columns}
              selectedRowKey={selectedRowKey}
              setSelectedRowKey={setSelectedRowKey}
              onDragEnd={(active, over) => {
                if (active === over) return;

                // activeIndex > overIndex 이면 overIndex를 +1 해줘야함
                const activeIndex = dataSource.findIndex(
                  (data) => data.id === active,
                );
                let overIndex = dataSource.findIndex(
                  (data) => data.id === over,
                );
                if (activeIndex < overIndex) overIndex += 1;

                const destinationId = dataSource[overIndex]?.id;

                // 마지막 순서로 드래그한 경우 nextOrderId를 null로 보냄 - RARENOTE-4152
                const isLast = overIndex === dataSource.length;

                setOrderHistory((prev) => [
                  ...prev,
                  { id: active, nextOrderId: isLast ? null : destinationId },
                ]);
              }}
              onChange={(_, filters, __, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (pagination.filter = filters),
                    (pagination.total = extra?.currentDataSource?.length),
                  ),
                );
              }}
              disableDnd={disableDnd}
              loading={loading}
            />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default HealthProfile;
