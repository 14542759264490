import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router';
import { parse } from 'qs';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
} from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import ElementLoading from '../../components/elementLoading';
import {
  createPapDocument,
  createPapProject,
  deletePapDocument,
  deletePapProject,
  fetchPapDocument,
  fetchPapProject,
  fetchPapProjectRequests,
  requestPapTermReAgreement,
  updatePapDocument,
  updatePapProject,
} from '../../../services/papService';
import { useFetch } from '../../../hooks/useRequest';
import WindowHeader from '../../components/windowHeader';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import AntTinymceInput from '../../components/antTinymceInput';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../util/utils';
import { PAP_STATUS } from '../../../util/papConstants';

const DEFAULT_DOCUMENTS = [
  {
    title: '입금 받을 통장 사본',
    description:
      '은행, 계좌번호, 예금주가 모두 보이도록 촬영, 은행앱으로 발급한 통장 사본도 등록 가능',
    defaultPhotoKey: 'bankAccount',
    type: 'patient',
  },
  {
    title: '진료비 세부 내역서 또는 처방전',
    description: '3개월 이내에 발급 서류만 가능',
    defaultPhotoKey: 'prescription',
    type: 'patient',
    requestOnRetry: true,
  },
];

const FIXED_DOCUMENTS = ['bankAccount'];

const PapProjectWindow = () => {
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [projectId, setProjectId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const [documentModalLoading, setDocumentModalLoading] = useState(false);
  const [documentModalOpen, setDocumentModalOpen] = useState(null);
  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const [documentChanged, setDocumentChanged] = useState([]);
  const [documentDeleted, setDocumentDeleted] = useState([]);
  const [termDeleted, setTermDeleted] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [recentNotifiedAt, setRecentNotifiedAt] = useState(null);

  const dispatch = useDispatch();
  const { diseaseTypeData } = useSelector((state) => {
    const diseaseTypes = state.diseaseTypeReducer.diseaseTypes?.data;
    return {
      diseaseTypeData: diseaseTypes,
    };
  });
  const getData = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const { data: PapProjectData, call: fetchPapProjectData } = useFetch(
    null,
    fetchPapProject,
    projectId,
  );

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const initialState = {
    title: '',
    alias: '',
    createdAt: moment(),
    diseaseTypeIds: [],
    status: 'ready',
    btnLabel: '',
    description: '',
    shareLink: null,
    uriScheme: null,
    agreements: [],
    documents: [],
    nokAgreements: [],
    nokDocuments: [],
  };
  const [state, setState] = useReducer(stateReducer, initialState);
  const [documentId, setDocumentId] = useState(null);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setProjectId(params.id);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    if (projectId) {
      fetchPapProjectData();
    }
  }, [fetchPapProjectData, projectId]);

  useEffect(() => {
    if (PapProjectData) {
      setState({
        ...PapProjectData,
        createdAt: moment(PapProjectData.createdAt),
      });
      form.setFieldsValue({
        ...PapProjectData,
        createdAt: moment(PapProjectData.createdAt),
        diseaseTypeIds: PapProjectData.diseaseTypes.map(
          (diseaseType) => diseaseType.id,
        ),
      });
      const allAgreements = [
        ...PapProjectData.agreements,
        ...PapProjectData.nokAgreements,
      ].filter((agreement) => agreement.recentNotifiedAt);
      if (allAgreements.length > 0) {
        setRecentNotifiedAt(
          moment(
            Math.max(
              ...allAgreements.map((agreement) =>
                moment(agreement.recentNotifiedAt).valueOf(),
              ),
            ),
          ),
        );
      }
    }
  }, [PapProjectData]);

  const [form] = Form.useForm();
  const { Option } = Select;

  const handleCreateAgreementsWindow = (type, agreement) => {
    if (findWindowById(agreement?.id)) {
      alert('이미 편집중인 약관입니다.');
      return;
    }
    createWindow({
      id: agreement?.termId ?? `${NEW}${Date.now()}`,
      dataType: 'papTerm',
      isNok: type === 'nokAgreement',
      versionId: agreement?.id,
    });
  };

  const receiveMessage = useCallback(
    async (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id, ...rest] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      if (command === 'agreement') {
        const result = JSON.parse(rest.join(' '));
        let isPatch = false;
        const newArray = state.agreements.map((agreement) => {
          if (agreement.termId === result.termId) {
            isPatch = true;
            return result;
          }
          return agreement;
        });
        if (!isPatch) {
          newArray.push(result);
        }
        setState({
          agreements: newArray,
        });
        form.setFieldsValue({
          agreements: newArray,
        });
        if (projectId) {
          /**
           * NOTE(reo): 약관 수정 후 프로젝트 저장 없이 바로 알림을 보내면 수정된 약관에 대한 알림이 보내지지 않아 약관 부분만 선 저장
           * @see RARENOTE-3964
           */
          try {
            await updatePapProject(projectId, {
              agreements: newArray.map((agreement) => agreement.termId),
            });
            notification.success({
              key: 'agreementSuccess',
              message: '약관 변경사항이 저장되었습니다.',
            });
          } catch (e) {
            notification.error({
              key: 'agreementFailed',
              message: '약관 변경사항 저장에 실패했습니다.',
            });
          }
        }
      }
      if (command === 'nokAgreement') {
        const result = JSON.parse(rest.join(' '));
        let isPatch = false;
        const newArray = state.nokAgreements.map((agreement) => {
          if (agreement.termId === result.termId) {
            isPatch = true;
            return result;
          }
          return agreement;
        });
        if (!isPatch) {
          newArray.push(result);
        }
        setState({
          nokAgreements: newArray,
        });
        form.setFieldsValue({
          nokAgreements: newArray,
        });
        if (projectId) {
          try {
            await updatePapProject(projectId, {
              nokAgreements: newArray.map((agreement) => agreement.termId),
            });
            notification.success({
              key: 'agreementSuccess',
              message: '약관 변경사항이 저장되었습니다.',
            });
          } catch (e) {
            notification.error({
              key: 'agreementFailed',
              message: '약관 변경사항 저장에 실패했습니다.',
            });
          }
        }
      }
    },
    [destroyWindowById, form, projectId, state.agreements, state.nokAgreements],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const [documentForm] = Form.useForm();

  const fetchDocument = useCallback(async (id) => {
    const res = await fetchPapDocument(id);
    return res;
  }, []);

  useEffect(() => {
    if (documentId) {
      fetchDocument(documentId).then((data) => {
        documentForm.setFieldsValue({
          ...data,
        });
      });
    } else {
      documentForm.resetFields();
    }
  }, [documentForm, documentId, fetchDocument]);

  const handleDeleteDocument = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      setDocumentDeleted([...documentDeleted, id]);
      setState({
        documents: state.documents.filter((document) => document.id !== id),
        nokDocuments: state.nokDocuments.filter(
          (document) => document.id !== id,
        ),
      });
      form.setFieldsValue({
        documents: state.documents.filter((document) => document.id !== id),
        nokDocuments: state.nokDocuments.filter(
          (document) => document.id !== id,
        ),
      });
      if (projectId) {
        await updatePapProject(projectId, {
          documents: state.documents
            .filter((document) => document.id !== id)
            .map((document) => document.id),
          nokDocuments: state.nokDocuments
            .filter((document) => document.id !== id)
            .map((document) => document.id),
        });
        notification.success({
          key: 'documentSuccess',
          message: '서류 변경사항이 저장되었습니다.',
        });
      }
    }
  };

  const handleSubmitDocument = async (id, isNok = false) => {
    setDocumentModalLoading(true);
    const property = isNok ? 'nokDocuments' : 'documents';
    const newArray = [...state[property]];
    let result = null;
    const POST_BODY = {
      ...documentForm.getFieldsValue(),
      type: isNok ? 'nok' : 'patient',
    };
    if (id) {
      // PATCH
      result = await updatePapDocument(id, POST_BODY);
      newArray.forEach((document, index) => {
        if (document.id === id) {
          newArray[index] = result;
        }
      });
    } else {
      // POST
      result = await createPapDocument(POST_BODY);
      newArray.push(result);
      if (projectId) {
        await updatePapProject(projectId, {
          [property]: newArray.map((document) => document.id),
        });
      }
    }
    if (result) {
      setState({
        [property]: newArray,
      });
      form.setFieldsValue({
        [property]: newArray,
      });
    }
    if (projectId) {
      notification.success({
        key: 'documentSuccess',
        message: '서류 변경사항이 저장되었습니다.',
      });
    }
    setDocumentModalLoading(false);
    setDocumentId(null);
    setDocumentModalOpen(null);
  };

  const handleDelete = async () => {
    if (
      window.confirm(
        '해당 PAP를 삭제하시겠습니까?\n기존에 등록된 약관 동의 항목, 서류 업로드 항목이 모두 삭제됩니다.',
      )
    ) {
      const projectRequest = await fetchPapProjectRequests(projectId);
      if (projectRequest.data.length > 0) {
        notification.error({
          key: 'deleteFailed',
          message: '이미 해당 PAP에 신청한 유저가 있어서 삭제가 불가능합니다.',
        });
        return;
      }
      await deletePapProject(projectId);
      alert('삭제되었습니다.');
      window.close();
    }
  };

  const handleSubmit = async (values) => {
    const REQ_BODY = {
      ...values,
    };
    REQ_BODY.documents = state.documents.map((document) => document.id);
    REQ_BODY.agreements = state.agreements.map((agreement) => agreement.termId);
    REQ_BODY.nokDocuments = state.nokDocuments.map(
      (nokDocument) => nokDocument.id,
    );
    REQ_BODY.nokAgreements = state.nokAgreements.map(
      (nokAgreement) => nokAgreement.termId,
    );
    // TODO: form validation?

    if (window.confirm('저장하시겠습니까?')) {
      setSubmitLoading(true);
      if (!projectId) {
        // 기본 항목들 생성 후 첨부
        await Promise.all(
          DEFAULT_DOCUMENTS.map(async (document) => {
            const BODY = {
              ...document,
              isDefault: true,
            };
            const result = await createPapDocument(BODY);
            if (document.type === 'patient') REQ_BODY.documents.push(result.id);
            if (document.type === 'nok') REQ_BODY.nokDocuments.push(result.id);
          }),
        );
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      if (documentChanged.length > 0) {
        await Promise.all([
          ...documentChanged.map((document) => {
            return updatePapDocument(document.id, document);
          }),
        ]);
      }
      if (documentDeleted.length > 0) {
        await Promise.all([
          ...documentDeleted.map((document) => {
            return deletePapDocument(document);
          }),
        ]);
      }
      if (projectId) {
        await updatePapProject(projectId, REQ_BODY);
      } else {
        await createPapProject(REQ_BODY);
      }
      alert('저장되었습니다.');
      window.close();
    }
    setSubmitLoading(false);
  };

  const handleDeleteTerm = async (termId) => {
    if (window.confirm('삭제하시겠습니까?')) {
      setTermDeleted([...termDeleted, termId]);
      const newAgreements = {
        agreements: state.agreements.filter(
          (agreement) => agreement.termId !== termId,
        ),
        nokAgreements: state.nokAgreements.filter(
          (agreement) => agreement.termId !== termId,
        ),
      };
      setState(newAgreements);
      form.setFieldsValue(newAgreements);
      if (projectId) {
        try {
          await updatePapProject(projectId, {
            agreements: newAgreements.agreements.map((a) => a.termId),
            nokAgreements: newAgreements.nokAgreements.map((a) => a.termId),
          });
          notification.success({
            key: 'agreementSuccess',
            message: '약관 변경사항이 저장되었습니다.',
          });
        } catch (e) {
          notification.error({
            key: 'agreementFailed',
            message: '약관 변경사항 저장에 실패했습니다.',
          });
        }
      }
    }
  };

  const agreement_columns = [
    {
      title: '약관명',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            handleCreateAgreementsWindow('agreement', record);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format('YY.MM.DD HH:mm'),
    },
    {
      title: '최근 약관 변경일시',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => moment(text).format('YY.MM.DD HH:mm'),
    },
    {
      title: '약관 삭제',
      key: 'deleteAction',
      render: (text, record) => (
        <Button
          danger
          onClick={() => {
            handleDeleteTerm(record.termId);
          }}
        >
          삭제
        </Button>
      ),
    },
  ];

  const nokAgreement_columns = [
    {
      title: '약관명',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            handleCreateAgreementsWindow('nokAgreement', record);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format('YY.MM.DD HH:mm'),
    },
    {
      title: '최근 약관 변경일시',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => moment(text).format('YY.MM.DD HH:mm'),
    },
    {
      title: '약관 삭제',
      key: 'deleteAction',
      render: (text, record) => (
        <Button
          danger
          onClick={() => {
            handleDeleteTerm(record.termId);
          }}
        >
          삭제
        </Button>
      ),
    },
  ];

  const document_columns = [
    {
      title: '사진 업로드 요청 항목',
      key: 'title',
      dataIndex: 'title',
      render: (text, record) =>
        FIXED_DOCUMENTS.includes(record.defaultPhotoKey) ? (
          <div style={{ padding: '4px 15px' }}>{text}</div>
        ) : (
          <Button
            type="link"
            onClick={() => {
              setDocumentId(record.id);
              setDocumentModalOpen('patient');
            }}
          >
            {text}
          </Button>
        ),
    },
    {
      title: '항목 삭제',
      key: 'deleteAction',
      render: (value, record) =>
        record.isDefault ? (
          <></>
        ) : (
          <Button
            danger
            onClick={() => {
              handleDeleteDocument(record.id);
            }}
          >
            삭제
          </Button>
        ),
    },
    {
      title: 'N차 신청 시 요청 여부',
      key: 'requestOnRetry',
      dataIndex: 'requestOnRetry',
      render: (value, record) =>
        FIXED_DOCUMENTS.includes(record.defaultPhotoKey) ? (
          <></>
        ) : (
          <Switch
            checked={value}
            onClick={async () => {
              const newDocuments = state.documents.map((document) => {
                if (document.id === record.id) {
                  return {
                    ...document,
                    requestOnRetry: !value,
                  };
                }
                return document;
              });
              setDocumentChanged([
                ...documentChanged,
                { id: record.id, requestOnRetry: !value },
              ]);
              setState({
                documents: newDocuments,
              });
              form.setFieldsValue({
                documents: newDocuments,
              });
            }}
          />
        ),
    },
    {
      title: '안내 문구',
      key: 'description',
      dataIndex: 'description',
    },
  ];

  const nokDocument_columns = [
    {
      title: '사진 업로드 요청 항목',
      key: 'title',
      dataIndex: 'title',
      render: (text, record) =>
        FIXED_DOCUMENTS.includes(record.defaultPhotoKey) ? (
          <div style={{ padding: '4px 15px' }}>{text}</div>
        ) : (
          <Button
            type="link"
            onClick={() => {
              setDocumentId(record.id);
              setDocumentModalOpen('nok');
            }}
          >
            {text}
          </Button>
        ),
    },
    {
      title: '항목 삭제',
      key: 'deleteAction',
      render: (value, record) =>
        record.isDefault ? (
          <></>
        ) : (
          <Button
            danger
            onClick={() => {
              handleDeleteDocument(record.id);
            }}
          >
            삭제
          </Button>
        ),
    },
    {
      title: '안내 문구',
      key: 'description',
      dataIndex: 'description',
    },
  ];

  if (isLoading) return <ElementLoading type="운영 > PAP" />;
  return (
    <>
      <WindowHeader title="운영 > PAP" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={(error) => {
              notification.error({
                key: 'onFailed',
                message: '저장에 실패했습니다. 필수 항목을 확인해주세요.',
              });
            }}
            initialValues={initialState}
          >
            <Form.Item
              label="PAP 명"
              name="title"
              tooltip="앱에 노출될 PAP 이름을 설정해주세요."
              rules={[
                {
                  required: true,
                  message: 'PAP 이름을 확인해주세요.',
                },
              ]}
            >
              <Input placeholder="예) 코셀루고" />
            </Form.Item>
            <Form.Item
              label="등록일"
              name="createdAt"
              rules={[
                {
                  type: 'object',
                  required: true,
                  message: '등록일을 확인해주세요.',
                },
              ]}
            >
              <DatePicker
                onChange={(date, dateString) => {
                  setState({
                    createdAt: dateString,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="질환 선택"
              name="diseaseTypeIds"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: '질환을 지정해주세요.',
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="질환 태그를 선택해주세요"
                filterOption={(input, option) => option.key.includes(input)}
              >
                {diseaseTypeData?.map((diseaseType) => (
                  <Option
                    key={diseaseType?.krName}
                    id={diseaseType?.krName}
                    value={diseaseType?.id}
                  >
                    {diseaseType.krName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="신청 상태" name="status">
              <Radio.Group>
                {Object.entries(PAP_STATUS).map(([key, value]) => (
                  <Radio key={key} value={key}>
                    {value}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="신청 버튼 문구"
              name="btnLabel"
              tooltip="신청 상태가 신청 가능일 때만 신청 버튼 문구가 나타납니다."
              rules={[
                {
                  required: true,
                  message: '신청 버튼 문구를 확인해주세요.',
                },
              ]}
            >
              <Input placeholder="예) 안내 사항 숙지 후 신청하기" />
            </Form.Item>
            <Form.Item
              label="신청 상세"
              name="description"
              className="treatmentContent"
            >
              <LoadingOverlay
                active={imageLoading}
                spinner={<Spin />}
                text={<p>이미지를 업로드 하는 중</p>}
              >
                <AntTinymceInput
                  content={state.description}
                  setContent={(value) => {
                    setState({
                      description: value,
                    });
                    form.setFieldsValue({
                      description: value,
                    });
                  }}
                  setImageLoading={setImageLoading}
                />
              </LoadingOverlay>
            </Form.Item>
            <Form.Item
              label="PAP 약칭"
              name="alias"
              tooltip="환자번호 자동 생성 시 부여할 PAP 약칭을 입력하세요."
              rules={[
                {
                  required: true,
                  message: 'PAP 약칭을 확인해주세요.',
                },
              ]}
            >
              <Input placeholder="예) kor" />
            </Form.Item>
            <Form.Item
              label="공유 링크"
              name="shareLink"
              tooltip="해당 약제비 지원 상세 페이지를 외부 앱에 공유하고 유입되게 하기 위한 링크입니다.  PAP 등록 내용 작성 후 첫 저장 시 링크가 자동 생성됩니다."
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              label="URI Scheme"
              name="uriScheme"
              tooltip="QR 코드 생성을 위한 URI Scheme 값입니다. ‘앱스플라이어 > Onelink > QR-to-app’ 생성 시 ‘Android and iOS fallback’ 영역에 해당 값을 입력해주세요.  PAP 등록 내용 작성 후 첫 저장 시 링크가 자동 생성됩니다."
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item label="환자 약관 동의 항목" name="agreements">
              <div>
                <Button
                  onClick={() => {
                    handleCreateAgreementsWindow('agreement', null);
                  }}
                >
                  약관 동의 항목 추가하기
                </Button>
                {projectId && (
                  <>
                    <Button
                      onClick={async () => {
                        if (window.confirm('푸시 발송을 하시겠습니까?')) {
                          await requestPapTermReAgreement(projectId);
                          setRecentNotifiedAt(moment());
                          alert('푸시 발송이 완료되었습니다.');
                        }
                      }}
                      style={{
                        marginLeft: 8,
                        marginRight: 8,
                      }}
                    >
                      최근 약관 재동의 푸시 발송
                    </Button>
                    마지막 푸시 발송일시:{' '}
                    {recentNotifiedAt
                      ? recentNotifiedAt.format('YY.MM.DD HH:mm')
                      : '없음'}
                  </>
                )}
              </div>
              최근 변경된 약관에 대해 PAP를 신청했던 사람에게 재동의를 받고자
              한다면, 푸시 발송 버튼을 눌러 주세요.
              <Table
                columns={agreement_columns}
                dataSource={state.agreements}
                size="small"
                pagination={false}
                bordered
                style={{ marginTop: 8 }}
                rowKey={(record) => record.id}
              />
            </Form.Item>
            <Form.Item label="환자 서류 업로드 항목" name="documents">
              <Button
                onClick={() => {
                  setDocumentId(null);
                  setDocumentModalOpen('patient');
                }}
              >
                서류 업로드 항목 추가하기
              </Button>
              <ul style={{ marginTop: 4, paddingInlineStart: 20 }}>
                <li>
                  업로드를 요청할 서류명을 입력한 후 추가해 주세요. PAP를 2회
                  이상 신청(N차 신청)하는 유저도 제출해야 하는 서류라면, 토글을
                  ON으로 설정해 주세요.
                </li>
                <li>
                  PAP 신규 등록 시 이 화면에서는 기본 항목인 &#39;진료비 세부
                  내역서 또는 처방전 / 입금 받을 통장 사본&#39;이 보이지 않으나,
                  저장 후 다시 PAP 수정하는 화면에서는 기본 항목을 확인할 수
                  있습니다.
                </li>
              </ul>
              <Table
                columns={document_columns}
                dataSource={state.documents}
                size="small"
                pagination={false}
                bordered
                style={{ marginTop: 8 }}
                rowKey={(record) => record.id}
              />
            </Form.Item>
            <Form.Item
              label="대리인 약관 동의 항목"
              name="nokAgreements"
              rules={[
                {
                  required: true,
                  message: '하나 이상의 대리인 약관을 등록해주세요.',
                },
              ]}
            >
              <div>
                <Button
                  onClick={() => {
                    handleCreateAgreementsWindow('nokAgreement', null);
                  }}
                >
                  약관 동의 항목 추가하기
                </Button>
                {projectId && (
                  <>
                    <Button
                      onClick={async () => {
                        if (window.confirm('푸시 발송을 하시겠습니까?')) {
                          await requestPapTermReAgreement(projectId, true);
                          setRecentNotifiedAt(moment());
                          alert('푸시 발송이 완료되었습니다.');
                        }
                      }}
                      style={{
                        marginLeft: 8,
                        marginRight: 8,
                      }}
                    >
                      최근 약관 재동의 푸시 발송
                    </Button>
                    마지막 푸시 발송일시:{' '}
                    {recentNotifiedAt
                      ? recentNotifiedAt.format('YY.MM.DD HH:mm')
                      : '없음'}
                  </>
                )}
              </div>
              <Table
                columns={nokAgreement_columns}
                dataSource={state.nokAgreements}
                size="small"
                pagination={false}
                bordered
                style={{ marginTop: 8 }}
                rowKey={(record) => record.id}
              />
            </Form.Item>
            <Form.Item label="대리인 서류 업로드 항목" name="nokDocuments">
              <Button
                onClick={() => {
                  setDocumentId(null);
                  setDocumentModalOpen('nok');
                }}
                style={{
                  display: 'block',
                }}
              >
                서류 업로드 항목 추가하기
              </Button>
              대리인에게 서류 업로드를 요청하지 않아도 될 경우, 항목을 추가하지
              마세요. 앱에서 대리인 사진 업로드 프로세스는 생략됩니다.
              <Table
                columns={nokDocument_columns}
                dataSource={state.nokDocuments}
                size="small"
                pagination={false}
                bordered
                style={{ marginTop: 8 }}
                rowKey={(record) => record.id}
              />
            </Form.Item>
            {/* --------------------------------------------------------- */}

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: 100 }}
                loading={submitLoading}
              >
                저장
              </Button>
              {projectId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={handleDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
        <Modal
          title="사진 업로드 요청 항목"
          visible={documentModalOpen}
          onCancel={() => setDocumentModalOpen(null)}
          onOk={documentForm.submit}
          confirmLoading={documentModalLoading}
        >
          <Form
            onFinish={() => {
              handleSubmitDocument(documentId, documentModalOpen === 'nok');
              documentForm.resetFields();
            }}
            form={documentForm}
          >
            <Form.Item
              label="항목명"
              name="title"
              rules={[
                {
                  required: true,
                  message: '항목명을 확인해주세요',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="안내 문구" name="description">
              <Input placeholder="예) 발급한지 3개월 이내의 서류를 등록해 주세요. " />
            </Form.Item>
          </Form>
        </Modal>
      </Row>
    </>
  );
};

export default PapProjectWindow;
