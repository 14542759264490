// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const config = {
  API: {
    GraphQL: {
      endpoint:
        'https://5lehwuoh6jdk3fjf57mhz6y7b4.appsync-api.ap-northeast-2.amazonaws.com/graphql',
      region: 'ap-northeast-2',
      defaultAuthMode: 'apiKey',
      apiKey: 'da2-e24smbvdr5dh5gq2f3rhh6d5qu',
    },
  },
};

export default config;
