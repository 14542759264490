import { useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Input,
  Layout,
  notification,
  Row,
  Typography,
} from 'antd';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import DndTable from '../../components/dndTable';
import { HEALTH_PROFILE_QUESTION_TYPE } from '../../../util/healthProfileConstants';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';
import {
  copyHealthProfileQuestions,
  deleteHealthProfileQuestions,
  fetchAllHealthProfileQuestion,
  patchHealthProfileQuestions,
} from '../../../services/healthProfileService';
import { CONFLICT } from '../../../services/utils';

const TagWrapper = ({ text }) => (
  <div className="tagWrapper" style={{ color: 'rgb(51, 51, 51)' }}>
    {text}
  </div>
);

const HealthProfileQuestion = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [filter, setFilter] = useState({});
  const [total, setTotal] = useState(0);
  const confirmRef = useRef(() => {});

  const disableDnd =
    Object.values(filter).filter((v) => v !== null).length > 0 ||
    state.hasAnsweredUser;

  const getData = useCallback(async () => {
    if (state.healthProfileId === undefined) return;
    const questions = await fetchAllHealthProfileQuestion(
      state.healthProfileId,
    );
    setDataSource(questions);
    setTotal(questions.length);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  // 데이터 갱신(복사, 삭제, 추가 등), 필터 적용시 순서 원복
  useEffect(() => {
    if (disableDnd && orderHistory.length > 0) {
      notification.warning({
        message: '문항 순서 변경이 저장되지 않고 초기화되었습니다.',
        key: 'disableDndWarning',
      });
      setOrderHistory([]);
      getData();
    }
  }, [disableDnd, dataSource]);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const handleCreateNewQuestionWindow = () => {
    if (state.hasAnsweredUser) {
      notification.error({
        message: '이미 응답한 사용자가 있어 문항을 추가할 수 없습니다.',
        key: 'hasAnsweredUserError',
      });
      return;
    }
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'healthProfileQuestion',
      healthProfileId: state.healthProfileId,
      hasAnsweredUser: state.hasAnsweredUser,
      title: state.title,
    });
  };

  const handleCreateEditQuestionWindow = (id) => {
    if (findWindowById(id)) {
      alert('이미 편집중인 문항입니다.');
      return;
    }
    createWindow({
      id,
      dataType: 'healthProfileQuestion',
      healthProfileId: state.healthProfileId,
      hasAnsweredUser: state.hasAnsweredUser,
      title: state.title,
    });
  };

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
      setOrderHistory([]);
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const confirmOrder = async () => {
    try {
      await patchHealthProfileQuestions(state.healthProfileId, orderHistory);
      notification.success({
        message: '문항 순서가 변경되었습니다.',
        key: 'confirmOrderSuccess',
      });
      setOrderHistory([]);
    } catch (e) {
      let description;
      if (e.data) {
        if (e.data.status === CONFLICT) {
          description =
            '이미 진행중이거나 완료된 설문이어서 순서를 변경할 수 없습니다.';
        }
      }
      notification.error({
        message: '문항 순서 변경에 실패했습니다.',
        key: 'confirmOrderFailed',
        description,
      });
      throw e;
    }
  };

  const onSearch = (value) => {
    confirmRef.current(value);
  };

  const onDelete = async () => {
    if (selectedRowKey.length === 0) return;
    if (window.confirm('선택한 문항들을 삭제하시겠습니까?')) {
      try {
        await deleteHealthProfileQuestions(
          state.healthProfileId,
          selectedRowKey,
        );
        notification.success({
          message: '문항이 삭제되었습니다.',
          key: 'deleteSuccess',
        });
        setSelectedRowKey([]);
        getData();
      } catch (e) {
        let description = undefined;
        if (e.data) {
          if (e.data.status === CONFLICT) {
            description =
              '이미 진행중이거나 완료된 설문이어서 삭제할 수 없습니다.';
          }
        }
        notification.error({
          message: '문항 삭제에 실패했습니다.',
          description,
          key: 'deleteFailed',
        });
        if (!description) throw e;
      }
    }
  };

  const onCopy = async () => {
    if (state.hasAnsweredUser) {
      notification.error({
        message: '이미 응답한 사용자가 있어 문항을 복사할 수 없습니다.',
        key: 'hasAnsweredUserError',
      });
      return;
    }
    if (selectedRowKey.length === 0) return;
    if (window.confirm('선택한 문항들을 복사하시겠습니까?')) {
      try {
        await copyHealthProfileQuestions(state.healthProfileId, selectedRowKey);
        notification.success({
          message: '문항이 복사되었습니다.',
          key: 'copySuccess',
        });
        setSelectedRowKey([]);
        getData();
      } catch (e) {
        notification.error({
          message: '문항 복사에 실패했습니다.',
          key: 'copyFailed',
        });
        throw e;
      }
    }
  };

  const columns = [
    {
      title: '순서 변경',
      dataIndex: 'sort',
      key: 'sort',
      width: 90,
      align: 'center',
    },
    {
      title: '선택',
      dataIndex: 'rowSelection',
      key: 'rowSelection',
      type: 'check',
      width: 60,
      align: 'center',
    },
    {
      title: '문항 제목',
      dataIndex: 'title',
      key: 'title',
      filteredValue: filter.title ? [filter.title] : null,
      onFilter: (value, record) =>
        record.title.includes(value) || record.description?.includes(value),
      filterDropdown: ({ confirm, setSelectedKeys }) => {
        confirmRef.current = (keyword) => {
          if (keyword.length > 0) {
            setSelectedKeys([keyword]);
            setFilter((prev) => ({ ...prev, title: keyword }));
          } else {
            setSelectedKeys(null);
            setFilter((prev) => ({ ...prev, title: null }));
          }

          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      render: (title, record) => (
        <Button
          type="link"
          onClick={() => handleCreateEditQuestionWindow(record.id)}
          style={{
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'normal',
          }}
        >
          {title}
        </Button>
      ),
    },
    {
      title: '문항 부가 설명',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: '답변 타입',
      dataIndex: 'type',
      key: 'type',
      render: (type) => HEALTH_PROFILE_QUESTION_TYPE[type] ?? '-',
      width: 110,
    },
    {
      title: '다음 문항 설정 여부',
      dataIndex: 'hasNextQuestion',
      key: 'hasNextQuestion',
      render: (hasNextQuestion) => (
        <TagWrapper text={hasNextQuestion ? '커스텀' : '기본 정렬 순'} />
      ),
      width: 130,
    },
    {
      title: '응답 필수 / 선택',
      dataIndex: 'isAnswerRequired',
      key: 'isAnswerRequired',
      render: (isAnswerRequired) => (
        <TagWrapper text={isAnswerRequired ? '필수' : '선택'} />
      ),
      width: 120,
    },
    {
      title: '답변 수정',
      dataIndex: 'isAnswerEditable',
      key: 'isAnswerEditable',
      render: (isAnswerEditable) => (
        <TagWrapper text={isAnswerEditable ? '가능' : '불가능'} />
      ),
      width: 80,
    },
    {
      title: '보호자 동의 필요',
      dataIndex: 'hasNokAgreementRequiredAnswer',
      key: 'hasNokAgreementRequiredAnswer',
      render: (hasNokAgreementRequiredAnswer) => (
        <TagWrapper text={hasNokAgreementRequiredAnswer ? '필요' : '불필요'} />
      ),
      width: 120,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="healthProfile" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="정보"
            subTitle="문항 / 답변 관리"
            className="white-bg"
          />
          <Layout.Content className="site-layout-background contentStyle">
            <Typography.Title level={4}>{state.title}</Typography.Title>
            <Row justify="space-between">
              <Row>
                <Button type="primary" onClick={() => history.goBack()}>
                  설문 목록으로 돌아가기
                </Button>
                <Button
                  type="primary"
                  disabled={orderHistory.length === 0}
                  onClick={confirmOrder}
                  style={{ marginLeft: 8 }}
                >
                  문항 순서 확정
                </Button>
              </Row>
              <Row justify="end">
                <span className="searchResult">검색결과 {total}개</span>
                <Input.Search
                  placeholder="검색어를 입력해주세요."
                  allowClear
                  className="searchStyle"
                  onSearch={onSearch}
                />
              </Row>
            </Row>
            <Divider style={{ margin: '0 0 12px 0' }} />
            <Row justify="space-between" style={{ marginBottom: 8 }}>
              <Row style={{ gap: 6 }}>
                <Button type="primary" onClick={handleCreateNewQuestionWindow}>
                  추가
                </Button>
                <Button
                  type="primary"
                  disabled={selectedRowKey.length === 0}
                  onClick={onCopy}
                >
                  복사
                </Button>
                <Button
                  type="primary"
                  disabled={selectedRowKey.length === 0}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              </Row>
            </Row>
            <DndTable
              dataSource={dataSource}
              setDataSource={setDataSource}
              columns={columns}
              selectedRowKey={selectedRowKey}
              setSelectedRowKey={setSelectedRowKey}
              onDragEnd={(active, over) => {
                if (active === over) return;

                // activeIndex > overIndex 이면 overIndex를 +1 해줘야함
                const activeIndex = dataSource.findIndex(
                  (data) => data.id === active,
                );
                let overIndex = dataSource.findIndex(
                  (data) => data.id === over,
                );
                if (activeIndex < overIndex) overIndex += 1;

                const destinationId = dataSource[overIndex]?.id;

                // 마지막 순서로 드래그한 경우 nextOrderId를 null로 보냄 - RARENOTE-4152
                const isLast = overIndex === dataSource.length;

                setOrderHistory((prev) => [
                  ...prev,
                  { id: active, nextOrderId: isLast ? null : destinationId },
                ]);
              }}
              onChange={(_, filters, __, extra) => {
                setFilter(filters);
                setTotal(extra?.currentDataSource?.length);
              }}
              disableDnd={disableDnd}
            />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default HealthProfileQuestion;
