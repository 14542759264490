import { createAction, handleActions } from 'redux-actions';
import { setValueReducer } from '../reducer.util';

const initialState = {
  tabPage: [
    'tabDashboard',
    'tabAccount',
    'tabData',
    'tabContent',
    'tabSubContent',
    'tabOperation',
    'tabDevelop',
    'mediaMonitor',
  ],
  dashboardDataPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  userPagination: {
    page: 1,
    text: '',
    filter: {},
    sorter: {},
    pageState: undefined,
    total: null,
  },
  mutationDnaResearchPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  hospitalExaminationPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },

  diseaseTypeCertificationsPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  researchPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  geneResearchPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  treatmentNewsPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  treatmentNewsOldPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  contentsInfoPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  diseaseInfoPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  hospitalRecordPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  dnaPagination: {
    page: 1,
    filter: {},
    total: null,
  },
  healthProfilePagination: {
    category: 'NORMAL',
    text: '',
    filter: {},
    total: null,
  },
  healthProfileUserPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  entryModalPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  noticesPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  patientGroupPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  patientGroupUserPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  remoteConfigPagination: {
    page: 1,
    text: '',
    filter: {},
    total: null,
  },
  // Nest API Pagination
  rssFeedPagination: {
    page: 1,
    pageSize: 20,
    filter: {},
    sort: { order: 'descend', field: 'createdAt', columnKey: 'createdAt' },
    totalCount: 0,
    totalPage: 1,
  },
  papProjectPagination: {
    page: 1,
    filter: { isApp: null, status: null, keyword: null },
    sorter: null,
    total: 0,
  },
  papRequestPagination: {
    page: 1,
    filter: {
      requestDate: null,
      prescriptionDate: null,
      version: null,
      status: null,
      keyword: null,
    },
    sorter: null,
    total: 0,
  },
};

export const paginationActions = {
  SET_VALUE: '@pagination/SET_VALUE',
};

export const paginationCreators = {
  setValue: createAction(paginationActions.SET_VALUE),
};

const paginationReducer = handleActions(
  {
    [paginationActions.SET_VALUE]: setValueReducer,
  },
  initialState,
);

export default paginationReducer;
